/**
 * NHMD's GBIF organization UUID key
 * https://www.gbif.org/developer/registry#p_publishingOrg
 */
export const NHMD_GBIF_ORG_UUID = '2e7df380-8356-4533-bcb3-5459e23c794e';

/**
 * List of collections that should be displayed on the website.
 *
 * label: matches the key for a translatable string found in public/locales/[da|en]/common.json
 * slug: the URL path that will be used on the website.
 * key: The datasetKey assigned to the collection on GBIF.
 * image: the URL of a IIIF image (in the /info.json this is the `@id` field)
 * imageFocusPoint: Where the focus on the image should be. Options are: center, left, right, top, bottom.
 * staff: An array of groups of curators, each with:
 *   name: the name of the curator
 *   email: the email address of the curator
 *   image: the URL of an image of the curator
 */
export const COLLECTIONS = [
  {
    label: 'biocultural',
    slug: 'BC',
    key: 'acf5050c-3a41-4345-a660-652cb9462379',
    image: '/images/heroes/bio-cultural.jpg',
    imageFocusPoint: 'left',
    staff: [
      {
        titleDa: 'Kurator for biokulturelle samling',
        titleEn: 'Curator of Biocultural Collection',
        people: [
          {
            name: 'Natasha de Vere',
            email: 'natasha.de.vere@snm.ku.dk',
          },
        ],
      },
    ],
  },
  {
    label: 'botany',
    slug: 'C',
    key: '0d1f9cee-7cb7-4d3a-a8c4-d2ca6edcd23b',
    image: '/images/heroes/botany.jpg',
    imageFocusPoint: 'left',
    staff: [
      {
        titleDa: 'Kurator for botanik',
        titleEn: 'Curator of Botany',
        people: [
          {
            name: 'Natasha de Vere',
            email: 'natasha.de.vere@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Kurator for algeherbariet',
        titleEn: 'Curator of the Algal Herbarium',
        people: [
          {
            name: 'Nina Lundholm',
            email: 'nlundholm@snm.ku.dk ',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejdere for botanik',
        titleEn: 'Collection Managers for Botany',
        people: [
          {
            name: 'Christian Lange',
            email: 'christianl@snm.ku.dk',
          },
        ],
      },
    ],
  },
  {
    label: 'invertebrates',
    slug: 'IV',
    key: '58fc397b-eee4-4138-8a5f-50f9dfb00216',
    image:
      '/images/heroes/invertebrates.jpg',
    imageFocusPoint: 'center',
    staff: [
      {
        titleDa: 'Kurator for Mollusca, Plathyhelminthes, Cnidaria, - Ctenophora og Prorifera',
        titleEn: 'Curator of Mollusca, Plathyhelminthes, Cnidaria, - Ctenophora and Prorifera',
        people: [
          {
            name: 'Martin Vinther Sørensen',
            email: 'mvsorensen@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Kurator for Echinodermata, ‘Tentaculata’, Tunicata, Crustacea og ‘Vermes’ (Annelida etc)',
        titleEn: 'Curator of Echinodermata, ‘Tentaculata’, Tunicata, Crustacea and ‘Vermes’ (Annelida etc)',
        people: [
          {
            name: 'Jørgen Olesen',
            email: 'jolesen@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejdere for Plathyhelminthes, Cnidaria, Ctenophora og Porifera',
        titleEn: 'Collection Manager of Plathyhelminthes, Cnidaria, Ctenophora and Porifera',
        people: [
          {
            name: 'Laura Pavesi',
            email: 'lpavesi@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejdere for Mollusca, Echinodermata, ‘Tentaculata’ og Tunicata',
        titleEn: 'Collection Manager of Mollusca, Echinodermata, ‘Tentaculata’ and Tunicata',
        people: [
          {
            name: 'Tom Schiøtte',
            email: 'tschioette@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejdere for Crustacea og ‘Vermes’ (Annelida etc.)',
        titleEn: 'Collection Manager of Crustacea and ‘Vermes’ (Annelida etc.)',
        people: [
          {
            name: 'Danny Eibye-Jacobsen',
            email: 'dejacobsen@snm.ku.dk ',
          },
        ],
      },
    ],
  },
  {
    label: 'birds',
    slug: 'AV',
    key: 'e5c5cad9-b987-4bb4-b07b-85488c5fdd80',
    image:
      '/images/heroes/birds-hero.jpg',
    imageFocusPoint: 'left',
    staff: [
      {
        titleDa: 'Kurator for fugle',
        titleEn: 'Curator of Ornithology',
        people: [
          {
            name: 'Pete Andrew Hosner',
            email: 'peter.hosner@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejder for fugle',
        titleEn: 'Collection Manager of Ornithology',
        people: [
          {
            name: 'Gloria Ramello',
            email: 'gloria.ramello@snm.ku.dk',
          },
        ],
      },
    ],
  },
  {
    label: 'mammals',
    slug: 'MM',
    key: '78b270c5-a5fe-4f1d-b87e-eb0dd7b7ae02',
    image:
      '/images/heroes/mammals.jpg',
    imageFocusPoint: 'bottom',
    staff: [
      {
        titleDa: 'Kurator for Pattedyr',
        titleEn: 'Curator of Mammalogy',
        people: [
          {
            name: 'Peter Rask Møller',
            email: 'pdrmoller@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejder for Pattedyr',
        titleEn: 'Collection Manager of Mammalogy',
        people: [
          {
            name: 'Daniel Klingberg Johansson',
            email: 'dkjohansson@snm.ku.dk',
          },
        ],
      },
    ],
  },
  {
    label: 'danekrae',
    slug: 'DK',
    key: '5b5305ae-35d1-4992-9d1a-f447db84b471',
    image:
      '/images/heroes/danekrae-1.jpg',
    imageFocusPoint: 'left',
    staff: [
      {
        titleDa: 'Kurator og adjunkt',
        titleEn: 'Curator and Assistant Professor',
        people: [
          {
            name: 'Laura Jane Cotton',
            email: 'laura.cotton@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejder',
        titleEn: 'Collection Manager',
        people: [
          {
            name: 'Bent Lindow',
            email: 'lindow@snm.ku.dk ',
          },
        ],
      },
    ],
  },
  {
    label: 'amber',
    slug: 'AM',
    key: 'f506ae89-e503-414a-9e4c-fefee5b5a07b',
    image:
      '/images/heroes/amber.jpg',
    imageFocusPoint: 'left',
    staff: [
      {
        titleDa: 'Kurator for Ravsamlingen',
        titleEn: 'Curator of Amber Collections',
        people: [
          {
            name: 'Lars Vilhelmsen',
            email: 'lbvilhelmsen@snm.ku.dk',
            image: '/images/staff/lars-vilhelmsen.jpg',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejdere for ravsamlingen',
        titleEn: 'Collection Managers for amber',
        people: [
          {
            name: 'Sree Gayathree Selvantharan',
            email: 'selvantharan@snm.ku.dk',
            image: '/images/staff/sree-gayathree-selvantharan.jpg',
          },
          {
            name: 'Anders Alexander Illum',
            email: 'andersaillum@snm.ku.dk',
          },
          {
            name: 'Jan Pedersen',
            email: 'japedersen@snm.ku.dk',
            image: '/images/staff/jan-pedersen.jpg',
          },
        ],
      },
    ],
  },
  {
    label: 'vertebrate-paleontology',
    slug: 'VP',
    key: '8e1b54db-ce7f-402e-a36e-c1382f0b7b97',
    image:
      '/images/heroes/vertebrate-paleontology.jpg',
    imageFocusPoint: 'left',
    staff: [
      {
        titleDa: 'Kurator og adjunkt',
        titleEn: 'Curator and Assistant Professor',
        people: [
          {
            name: 'Laura Jane Cotton',
            email: 'laura.cotton@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejder',
        titleEn: 'Collection Manager',
        people: [
          {
            name: 'Bent Lindow',
            email: 'lindow@snm.ku.dk ',
          },
        ],
      },
    ],
  },
  {
    label: 'entomology',
    slug: 'EN',
    key: 'e0459621-92c2-40ac-9934-72b3b1384dc3',
    image:
      '/images/heroes/insects-1.jpg',
    imageFocusPoint: 'left',
    staff: [
      {
        titleDa: 'Kurator for Arachnida og Myriapoda',
        titleEn: 'Curator of Arachnidae and Myriapoda',
        people: [
          {
            name: 'Nikolaj Scharff',
            email: 'nscharff@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Kurator for Diptera og Lepidoptera',
        titleEn: 'Curator of Diptera and Lepidoptera',
        people: [
          {
            name: 'Thomas Pape',
            email: 'tpape@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Kurator for Coleoptera',
        titleEn: 'Curator of Coleoptera',
        people: [
          {
            name: 'Alexey Solodonikov',
            email: 'asolodovnikov@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Kurator for Hymenoptera, Hemiptera og øvrige underordner',
        titleEn: 'Curator of Hymenoptera, Hemiptera and lesser orders',
        people: [
          {
            name: 'Lars Vilhelmsen',
            email: 'lbvilhelmsen@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejdere for entomologi',
        titleEn: 'Collection Managers for Entomology',
        people: [
          {
            name: 'Sree Gayathree Selvantharan',
            email: 'selvantharan@snm.ku.dk',
          },
          {
            name: 'Anders Alexander Illum',
            email: 'andersaillum@snm.ku.dk',
          },
          {
            name: 'Jan Pedersen',
            email: 'japedersen@snm.ku.dk',
          },
        ],
      },
    ],
  },
  {
    label: 'herpetology',
    slug: 'HP',
    key: '8c834f97-c5df-4280-9623-86594979f91a',
    image:
      '/images/heroes/reptiles-5.jpg',
    imageFocusPoint: 'right',
    staff: [
      {
        titleDa: 'Kurator for krybdyr og padder',
        titleEn: 'Curator of Reptiles and Amphibians',
        people: [
          {
            name: 'Mark Scherz',
            email: 'mark.scherz@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejder for krybdyr og padder',
        titleEn: 'Collection Manager of Reptiles and Amphibians',
        people: [
          {
            name: 'Daniel Klingberg Johansson',
            email: 'dkjohansson@snm.ku.dk',
          },
        ],
      },
    ],
  },
  {
    label: 'ichthyology',
    slug: 'PI',
    key: '84dbaec2-f762-11e1-a439-00145eb45e9a',
    image:
      '/images/heroes/fish-2.jpg',
    imageFocusPoint: 'right',
    staff: [
      {
        titleDa: 'Kurator og lektor',
        titleEn: 'Curator and Associate Professor',
        people: [
          {
            name: 'Peter Rask Møller',
            email: 'PDRMoller@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejdere for Icthyology',
        titleEn: 'Collection Manager for Icthyology',
        people: [
          {
            name: 'Mackenzie Kinney',
            email: 'mki@snm.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Forskningspersonale',
        titleEn: 'Research Staff',
        people: [
          {
            name: 'Henrik Carl',
            email: 'hcarl@snm.ku.dk',
          },
        ],
      },
    ],
  },
  {
    label: 'quaternary-zoology',
    slug: 'QZ',
    key: '51f8bbe4-36e9-44c2-9f66-eaa5c7017a5c',
    image:
      '/images/heroes/quaternary-zoology-1.jpg',
    imageFocusPoint: 'center',
    staff: [
      {
        titleDa: 'Kurator og Professor',
        titleEn: 'Curator and Professor',
        people: [
          {
            name: 'Kurt H. Kjær',
            email: 'kurtk@sund.ku.dk',
          },
        ],
      },
      {
        titleDa: 'Samlingsmedarbejder',
        titleEn: 'Collection Manager',
        people: [
          {
            name: 'Bent Lindow',
            email: 'lindow@snm.ku.dk',
          },
        ],
      },
    ],
  },
];

/**
 * An array of collections to display on the homepage, consisting of:
 * - slug - matches the slug for the collection found in the COLLECTIONS array
 * - label - matches the key for a translatable string found in public/locales/[da|en]/common.json
 * - image - the URL of a IIIF image (in the /info.json this is the `@id` field)
 * - imageAltDa - descriptive alternative text for the image (in Danish)
 * - imageAltEn - descriptive alternative text for the image (in English)
 *
 * Suggested number: 3
 */
export const FEATURED_COLLECTIONS = [
  {
    slug: 'PI',
    label: 'Fish',
    image:
      '/images/heroes/fish-2.jpg',
    imageFocusPoint: 'right',
    imageAltDa: '',
    imageAltEn: '',
  },
  {
    slug: 'AV',
    label: 'birds',
    image:
      '/images/heroes/birds-hero.jpg',
    imageFocusPoint: 'left',
    imageAltDa: '',
    imageAltEn: '',
  },
  {
    slug: 'DK',
    label: 'danekrae',
    image:
      '/images/heroes/danekrae-1.jpg',
    imageFocusPoint: 'left',
    imageAltDa: '',
    imageAltEn: '',
  },
  {
    slug: 'C',
    label: 'botany',
    image: '/images/heroes/botany.jpg',
    imageFocusPoint: 'left',
    imageAltDa: '',
    imageAltEn: '',
  },
];

/**
 * An array of catalog numbers for objects to display on the homepage
 *
 * Suggested number: 4
 */
export const FEATURED_OBJECTS = ['NHMD616060', 'NHMD36837', 'NHMD615629', 'NHMD77326'];

/**
 * Defines the main image shown on the front page.
 */
export const HOMEPAGE_HERO_IMAGE = {
  src: '/images/heroes/homepage-hero.jpg',
  imageFocusPoint: 'center',
  altDa: '',
  altEn: '',
};

/**
 * Contact details for the museum, shown in footer.
 */
export const CONTACT_DETAILS = {
  email: 'snm@snm.dk',
  phone: '+45 35 32 22 22',
  enPhoneHours: 'Telephone hours : Mon-Fri 10-12am',
  daPhoneHours: 'Telefontid : Man-Fre kl. 10-12',
};

/**
 * An array of links to be shown in the footer of the website.
 *
 * - label - matches the key for a translatable string found in public/locales/[da|en]/common.json
 * - href - URLs to pages in Danish and English.
 */
export const ABOUT_LINKS = [
  {
    label: 'about-the-collections',
    href: {
      da: 'https://samlinger.snm.ku.dk/om_samlingerne/',
      en: 'https://samlinger.snm.ku.dk/en/about-the-collections/',
    },
  },
  {
    label: 'conservation-at-nhm-denmark',
    href: {
      da: 'https://samlinger.snm.ku.dk/toer-og-vaadsamlinger/konservering/',
      en: 'https://samlinger.snm.ku.dk/en/dry-and-wet-collections/conservation/',
    },
  },
  {
    label: 'meet-the-collection-staff',
    href: {
      da: 'https://samlinger.snm.ku.dk/samlingsforvaltning/kuratorer/',
      en: 'https://samlinger.snm.ku.dk/en/collection-management/curators/',
    },
  },
  {
    label: 'about-the-museum',
    href: {
      da: 'https://snm.ku.dk/omsnm/',
      en: 'https://snm.ku.dk/english/about_the_museum/',
    },
  },
  {
    label: 'harmful-content-statement',
    href: {
      da: 'https://samlinger.snm.ku.dk/om_samlingerne/erklaering-om-potentielt-stoedende-indhold/',
      en: 'https://samlinger.snm.ku.dk/en/about-the-collections/statement-of-potential-harmful-content/',
    },
  },
];

/**
 * An array of links to the museum's social media profiles, shown in footer.
 */
export const SOCIAL_LINKS = [
  {
    label: 'Facebook',
    href: 'https://www.facebook.com/StatensNaturhistoriskeMuseum/'
  },
  {
    label: 'Instagram',
    href: 'https://www.instagram.com/statensnaturhistoriskemuseum/'
  },
  {
    label: 'YouTube',
    href: 'https://www.youtube.com/user/NaturalHistoryDK'
  },
  {
    label: 'X',
    href: 'https://twitter.com/NHM_Denmark'
  },
];

/**
 * An array of vernacular names displayed as badges on the objects in the search results page.
 * They have been identified as popular accessible names for non-scientific users of the portal
 * NOTE: the software will add a 'trivial' or vernacular name from gbif if there is one.
 * If there isn't, it will use the values given in enTrivialName/daTrivialName instead.
 *
 * - canonical - field must match the canonical name of object in gbif
 * - enTrivialName - the English name that will be a fallback option for display in the badge
 * - daTrivialName - the Danish name that will be a fallback option for display in the badge
 */

export const VERNACULARNAMES = [
  {
    canonical: 'incertae sedis',
    enTrivialName: 'incertae sedis (unidentified)',
    daTrivialName: 'incertae sedis (uidentificeret)',
  },
  {
    canonical: 'Acanthocephala',
    enTrivialName: 'Thorny-headed worms',
    daTrivialName: 'Kradsere',
  },
  {
    canonical: 'Trilobita',
    enTrivialName: 'Trilobites',
    daTrivialName: 'Trilobitter',
  },
  {
    canonical: 'Annelida',
    enTrivialName: 'Segmented worms ',
    daTrivialName: 'Ledorme',
  },
  {
    canonical: 'Insecta',
    enTrivialName: 'Insects',
    daTrivialName: 'Insekter',
  },
  {
    canonical: 'Arthropoda',
    enTrivialName: 'Arthropods',
    daTrivialName: 'Leddyr',
  },
  {
    canonical: 'Brachiopoda',
    enTrivialName: 'Lamp Shells',
    daTrivialName: 'Armfødder',
  },
  {
    canonical: 'Bryozoa',
    enTrivialName: 'Moss Animals',
    daTrivialName: 'Mosdyr',
  },
  {
    canonical: 'Chaetognatha',
    enTrivialName: 'Arrow Worms',
    daTrivialName: 'Pilorme',
  },
  {
    canonical: 'Chordata',
    enTrivialName: 'Chordates',
    daTrivialName: 'Hvirveldyr',
  },
  {
    canonical: 'Cnidaria',
    enTrivialName: 'Cnidarians ',
    daTrivialName: 'Polypdyr',
  },
  {
    canonical: 'Ctenophora',
    enTrivialName: 'Comb jellies',
    daTrivialName: 'Ribbegopler',
  },
  {
    canonical: 'Cycliophora',
    enTrivialName: 'Symbion',
    daTrivialName: 'Ringbærer',
  },
  {
    canonical: 'Echinodermata',
    enTrivialName: 'Echinoderms',
    daTrivialName: 'Pighude',
  },
  {
    canonical: 'Entoprocta',
    enTrivialName: 'Globlet worms',
    daTrivialName: 'Mosdyr',
  },
  {
    canonical: 'Hemichordata',
    enTrivialName: 'Acorn worms, Pterobranchs',
    daTrivialName: '',
  },
  {
    canonical: 'Kinorhyncha',
    enTrivialName: 'Mud dragons',
    daTrivialName: 'Mudderdrager',
  },
  {
    canonical: 'Loricifera',
    enTrivialName: 'Bush heads',
    daTrivialName: 'Korsetdyr',
  },
  {
    canonical: 'Micrognathozoa',
    enTrivialName: 'Limnognathia',
    daTrivialName: '',
  },
  {
    canonical: 'Mollusca',
    enTrivialName: 'Molluscs',
    daTrivialName: 'Bløddyr',
  },
  {
    canonical: 'Nematoda',
    enTrivialName: 'Roundworms',
    daTrivialName: 'Rundorme',
  },
  {
    canonical: 'Nemertea',
    enTrivialName: 'Ribbon worms',
    daTrivialName: '',
  },
  {
    canonical: 'Onychophora',
    enTrivialName: 'Velvet worms',
    daTrivialName: 'Fløjlsdyr',
  },
  {
    canonical: 'Phoronida',
    enTrivialName: '',
    daTrivialName: '',
  },
  {
    canonical: 'Placozoa',
    enTrivialName: '',
    daTrivialName: '',
  },
  {
    canonical: 'Platyhelminthes',
    enTrivialName: 'Flatworms',
    daTrivialName: 'Fladorme',
  },
  {
    canonical: 'Porifera',
    enTrivialName: 'Sponges',
    daTrivialName: 'havsvampe',
  },
  {
    canonical: 'Priapulida',
    enTrivialName: 'Penis worms',
    daTrivialName: '',
  },
  {
    canonical: 'Rotifera',
    enTrivialName: 'Rotifers',
    daTrivialName: 'Hjuldyr',
  },
  {
    canonical: 'Tardigrada',
    enTrivialName: 'Water bears',
    daTrivialName: 'Bjørnedyr',
  },
  {
    canonical: 'Xenacoelomorpha',
    enTrivialName: '',
    daTrivialName: '',
  },
  {
    canonical: 'Crustacea',
    enTrivialName: '',
    daTrivialName: '',
  },
  {
    canonical: 'Actinopterygii',
    enTrivialName: '',
    daTrivialName: '',
  },
  {
    canonical: 'Aves',
    enTrivialName: '',
    daTrivialName: '',
  },
  {
    canonical: 'Amphibia',
    enTrivialName: '',
    daTrivialName: '',
  },
  {
    canonical: 'Reptilia',
    enTrivialName: '',
    daTrivialName: '',
  },
  {
    canonical: 'Mammalia',
    enTrivialName: '',
    daTrivialName: '',
  },
  {
    canonical: 'Gastropoda',
    enTrivialName: '',
    daTrivialName: '',
  },
  {
    canonical: 'Bivalvia',
    enTrivialName: '',
    daTrivialName: '',
  },
  {
    canonical: 'Cephalopoda',
    enTrivialName: '',
    daTrivialName: '',
  },
  {
    canonical: 'Rhombozoa',
    enTrivialName: '',
    daTrivialName: '',
  },
  // {
  //   canonical: '',
  //   enTrivialName: '',
  //   daTrivialName: '',
  // },
];
